import { itemPages } from '../config/constantLists'
import { getLastRoute } from '@/Utils/helper'

export default {
  props: { id: { type: [String, Number], default: '' } },
  data () {
    return {
      loading: true,
      currentPage: this.$route.meta.currentPage,
      itemPages,
      lastRoute: getLastRoute()
    }
  },
  computed: {
    submitLabel () {
      switch (this.currentPage) {
        case itemPages.create:
          return this.$t('global.create')
        case itemPages.update:
          return this.$t('global.update')
        default:
          return ''
      }
    },
    disableStatus () {
      switch (this.currentPage) {
        case itemPages.create:
        case itemPages.update:
          return false
        default:
          return true
      }
    }
  },
  methods: {
    formSubmit () {
      if (!this.loading) {
        this.loading = true
        switch (this.currentPage) {
          case itemPages.create:
            this.create()
            break
          case itemPages.update:
            this.update()
            break
          default:
            break
        }
      }
    }
  }
}
