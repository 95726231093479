import GeneralFilter from '../../../../Model/GeneralFilter'

export default class ContactUsFilter extends GeneralFilter {
  constructor () {
    super()
    this.setInitialValue()
  }

  setInitialValue () {
    this.setFilterInitialValue()
    this.contact_us_topic_id = ''
    this.user_id = ''
  }
}
