<template>
  <div>
    <b-card>
      <page-title v-if="titleStatus" :title="$t('contactUs.items')" />

      <main-table
        :fields="fields"
        :list_url="'admin/contact-us'"
        :reloadData="reloadData"
        :createStatus="false"
        :excelFields="excelFields"
        excelName="contactUs"
      />
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import ContactUsFilter from "../models/ContactUsFilter";
import searchMixin from "../../../../Mixins/searchMixin";

export default {
  mixins: [searchMixin],
  data() {
    return {
      filter: new ContactUsFilter(),
      fields: [
        this.$t("global.selected"),
        { key: "id", label: this.$t("table.id"), sortable: true },
        { key: "user.name", label: this.$t("clients.item"), sortable: true },
        {
          key: "contactTopic.name",
          label: this.$t("contactUsTopics.name"),
          sortable: true,
        },
        { key: "subject", label: this.$t("global.subject"), sortable: true },
        {
          key: "created_at",
          label: this.$t("table.createdAt"),
          type: "dateTime",
          sortable: true,
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          type: "actions",
          actions: [
            {
              text: this.$t("global.view"),
              icon: "fas fa-eye",
              color: "info",
              ifNavigate: true,
              routeName: "clients.contactUsView",
              routeParams: ["id"],
            },
          ],
        },
      ],
      excelFields: {
        [this.$t("table.id")]: "id",
        [this.$t("clients.item")]: "user.name",
        [this.$t("contactUsTopics.name")]: "contactTopic.name",
        [this.$t("global.subject")]: "subject",
        [this.$t("table.createdAt")]: "created_at",
      },
    };
  },
  props: {
    contactUsTopicId: { default: "" },
    userId: { default: "" },
  },
  mounted() {
    core.index();
  },
  created() {
    this.filter.contact_us_topic_id = this.contactUsTopicId;
    this.filter.user_id = this.userId;
  },
};
</script>
