<template>
  <div>
    <b-card>
      <ValidationObserver ref="contactUsTopic" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
          <b-row>
            <b-col md="6">
              <input-form
                v-model="contactUsTopic.contact_us_topic.translations[0].name"
                :name="$t('contactUsTopics.nameAr')"
                :label="$t('contactUsTopics.nameAr')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <input-form
                v-model="contactUsTopic.contact_us_topic.translations[1].name"
                :name="$t('contactUsTopics.nameEn')"
                :label="$t('contactUsTopics.nameEn')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>
          </b-row>

          <form-btns
            v-if="currentPage != itemPages.view"
            :label="submitLabel"
            :loading="loading"
          />
        </b-form>
      </ValidationObserver>
    </b-card>

    <b-card v-if="currentPage == itemPages.view">
      <contact-us :contactUsTopicId="id" :titleStatus="true" />
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import contactUsTopicServices from "../services/contactUsTopics";
import ContactUsTopic from "../models/ContactUsTopic";
import ContactUs from "../../../clients/contactUs/views/ContactUs.vue";
import moduleItemMixin from "../../../../Mixins/moduleItemMixin";

export default {
  mixins: [moduleItemMixin],
  components: { ContactUs },
  data() {
    return {
      contactUsTopic: new ContactUsTopic(),
    };
  },
  methods: {
    async create() {
      contactUsTopicServices
        .create({
          translations: this.contactUsTopic.contact_us_topic.translations,
        })
        .then((response) => {
          this.loading = false;
          core.showSnackbar("success", response.data.message);
          this.lastRoute.name
            ? this.$router.push(this.lastRoute)
            : this.$router.push({ name: "generalSettings.contactUsTopics" });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async update() {
      contactUsTopicServices
        .update(this.id, {
          translations: this.contactUsTopic.contact_us_topic.translations,
        })
        .then((response) => {
          this.loading = false;
          core.showSnackbar("success", response.data.message);
          this.lastRoute.name
            ? this.$router.push(this.lastRoute)
            : this.$router.push({ name: "generalSettings.contactUsTopics" });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    core.index();
  },
  created() {
    if (this.id) {
      contactUsTopicServices.findOne(this.id).then((response) => {
        this.contactUsTopic.fillData(response.data.data);
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  },
};
</script>
